var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('card-header',{attrs:{"title":"New Document","icon":"fa-plus"}}),_c('card-body',[_c('document-form',{attrs:{"doc":_vm.newDocument}}),_c('card-list',[(_vm.newDocument.file)?_c('container-list-item',[_c('data-row',{attrs:{"title":"File"}},[_vm._v(_vm._s(_vm.newDocument.file_name))]),_c('data-row',{attrs:{"title":"Hash"}},[_vm._v(_vm._s(_vm.newDocument.file))]),_c('data-row',{attrs:{"title":"Type"}},[_vm._v(_vm._s(_vm.newDocument.file_mime))])],1):_vm._e(),_c('container-list-item',{attrs:{"padded":""}},[_c('file-pond',{ref:"pond",attrs:{"name":"file","label-idle":"Drop or click to add document...","allow-multiple":false,"allowRevert":false,"files":_vm.files,"server":{
                            url: _vm.apiUrl,
                            timeout: 7000,
                            process: {
                                url: 'api/trips/upload',
                                method: 'POST',
                                headers: {
                                    'Authorization': _vm.token
                                },
                                withCredentials: false
                            }
                        }},on:{"init":_vm.handleFilePondInit,"processfile":_vm.handleFilePondProcess}})],1)],1)],1),_c('card-footer',{staticClass:"pt-3 pb-3 has-text-right"},[_c('b-button',{attrs:{"type":"is-primary","size":"is-small","disabled":!_vm.newDocument.subject},on:{"click":_vm.submit}},[_c('i',{staticClass:"fas fa-plus mr-3"}),_vm._v("New Document")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }