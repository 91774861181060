<template>
    <card-list>
        <subheader-list-item icon="fa-align-left" title="document information"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Title" label-position="inside">
                        <b-input maxlength="300" v-model="doc.subject"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Subtitle/Data/ID Number" label-position="inside">
                        <b-input maxlength="300" v-model="doc.id_number"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Additional Information" label-position="inside">
                        <b-input type="textarea" v-model="doc.information"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-switch :native-value="1" :true-value="1" :false-value="0" v-model="doc.is_app_visible">Visible in Navigator</b-switch>
                </div>
            </div>
        </container-list-item>
    </card-list>
</template>

<script>
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    export default {
        name: 'DocumentForm',
        props: {
            doc: {
                type: Object,
                required: true
            }
        },
        components: {ContainerListItem, SubheaderListItem, CardList}
    };
</script>
